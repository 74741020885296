import './MapPage.css'
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, Polyline} from 'react-leaflet';
import { LeafletMouseEvent, LatLng } from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import { LatLngExpression } from 'leaflet';
import RecenterMap from './RecenterMap';
import parkingMarker from './parking-marker.png'


const MapPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { building_id, building_name, building_coords, closest_lot, lot_coords } = location.state || {}; // Retrieve the passed state
    const [routeCoordinates, setRouteCoordinates] = useState<LatLngExpression[]>([]);
    const[centerCoordinates, setCenterCoordinates] = useState({
        latitude: 33.646,
        longitude: -117.843
    });
    const [routeDistance, setRouteDistance] = useState();
    const [routeTime, setRouteTime] = useState();
    useEffect (() => {
        if (closest_lot && building_coords && lot_coords) {
            /* DEBUGGING */
            // console.log('Search query received:', closest_lot);
            // console.log('building:', building_coords, 'lot:', lot_coords);

            const centerCoords = {
                latitude: (building_coords.latitude + lot_coords.latitude) / 2,
                longitude: (building_coords.longitude + lot_coords.longitude) / 2
            }
            setCenterCoordinates(centerCoords);

            // Fetch walking route between building and lot

            const fetchRoute = async () => {
                const apiKey = process.env.REACT_APP_API_KEY; 
                const startCoords = `${building_coords.longitude},${building_coords.latitude}`;
                const endCoords = `${lot_coords.longitude},${lot_coords.latitude}`;
                const url = `https://api.openrouteservice.org/v2/directions/foot-walking?api_key=${apiKey}&start=${startCoords}&end=${endCoords}`;

                try {
                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.features && data.features.length > 0) {
                        const coordinates: [number, number][] = data.features[0].geometry.coordinates; // Extract coordinates directly
                        // Convert to Leaflet's format: [lat, lng]
                        const latLngCoordinates: LatLngExpression[]= coordinates.map(coord => [coord[1], coord[0]]);
                        setRouteCoordinates(latLngCoordinates); // Update state with coordinates
                        const distance = data.features[0].properties.summary.distance;
                        setRouteDistance(distance);
                        const time = data.features[0].properties.summary.duration;
                        setRouteTime(time);
                        /* DEBUGGING */
                        // console.log(latLngCoordinates); // Log the converted coordinates

                    } else {
                        // console.error('No features found in the API response.');
                    }
                } catch (error) {
                    // console.error('Error fetching walking route:', error);
                }
            };
            fetchRoute();
        } 
        else {
            // console.log('Search query or selected building not available.');
          }
    }, [closest_lot, building_coords, lot_coords]);

     // Create a default marker icon
     const buildingIcon = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png', // we can customize the icon img here
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [41, 41],
    });

    const lotIcon = new L.Icon({
        iconUrl: parkingMarker,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [1, -34],
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [41, 41],
        shadowAnchor: [14, 40],
    });
    
    const handleSearchClick = () => {
        // Navigate to the SearchPage (adjust the path if needed)
        navigate('/search'); // Change '/search' to your desired route for SearchPage
    };


    const [popupPosition, setPopupPosition] = useState<LatLng | null>(null);
    const [popupContent, setPopupContent] = useState("");

    // Function to handle clicks on the route
    const handleRouteClick = (e: LeafletMouseEvent) => {
        setPopupPosition(e.latlng);
        if (routeDistance && routeTime) {
            setPopupContent("<strong>Walking Route</strong>" +
                "<br />Duration: " + Math.floor(routeTime / 60) + " min" + 
                "<br />Distance: " + (routeDistance * 0.000621371).toFixed(2) + " miles"
            );
        }
    };

    // Function to handle hovering on the route
    const handleRouteHover = (e: LeafletMouseEvent) => {
        setPopupPosition(e.latlng);
        if (routeDistance && routeTime) {
            setPopupContent("<strong>Walking Route</strong>" +
                "<br />Duration: " + Math.floor(routeTime / 60) + " min" + 
                "<br />Distance: " + (routeDistance * 0.000621371).toFixed(2) + " miles"
            );
        }
    };

    // Function to handle popup close
    const handlePopupClose = () => {
        setPopupPosition(null);
    };

    return (
        <div className="map-container">
            <div className="overlay">
                <h1 className="title-map-page">ZOT PARK</h1>
                {/* Search Bar with Icon */}
                <div className="search-icon-button" onClick={handleSearchClick}>
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </div>
            </div>

            <MapContainer className="map" center={[centerCoordinates.latitude, centerCoordinates.longitude]} zoom={17} style={{ height: '100%', width: '100%'}}>
            <RecenterMap center={centerCoordinates} />
            <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* Marker for the building */}
            {building_coords && (
                    <Marker position={[building_coords.latitude, building_coords.longitude]} icon={buildingIcon}>
                        <Popup>
                            <strong>{building_name} ({building_id})</strong>
                            <br />
                            <a href={`https://www.google.com/maps/dir/?api=1&destination=${building_coords.latitude},${building_coords.longitude}`} 
                                target="_blank" rel="noreferer">
                                    Directions to building <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm"/></a>
                        </Popup>
                    </Marker>
                )}

            {/* Marker for the closest parking lot */}
            {lot_coords && (
                    <Marker position={[lot_coords.latitude, lot_coords.longitude]} icon={lotIcon}>
                        <Popup>
                            <strong>{closest_lot}</strong>
                            <br />
                            <a href={`https://www.google.com/maps/dir/?api=1&destination=${lot_coords.latitude},${lot_coords.longitude}`} 
                                target="_blank" rel="noreferer">
                                    Directions to lot <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm"/></a>
                        </Popup>
                    </Marker>
                )}
            {/* Draw the walking route */}
            {routeCoordinates.length > 0 && (
                <Polyline 
                    positions={routeCoordinates}
                    eventHandlers={{
                        click: handleRouteClick,
                        mouseover: handleRouteHover
                    }}
                    color="blue"
                />
            )}

            {popupPosition && (
                <Popup
                    position={popupPosition}
                    eventHandlers={{
                        remove: handlePopupClose,
                    }}
                >
                <div dangerouslySetInnerHTML={{__html: popupContent}} />
                </Popup>
            )}

            </MapContainer>
        </div>
    );
};


export default MapPage;
